// Overrides for default theme from Vendure
// ~@vendure/admin-ui/static/styles/theme/default
[data-theme='default'] {
  // Primary button colors
  --clr-btn-primary-color: var(--clr-color-neutral-0);
  --clr-btn-primary-bg-color: var(--clr-color-action-500);
  --clr-btn-primary-border-color: var(--clr-color-action-500);
  --clr-btn-primary-hover-bg-color: var(--clr-color-action-600);
  --clr-btn-primary-hover-color: var(--clr-color-neutral-0);
  --clr-btn-primary-box-shadow-color: var(--clr-color-action-500);
  --clr-btn-primary-checked-bg-color: var(--clr-color-action-600);
  --clr-btn-primary-checked-color: var(--clr-color-neutral-0);

  // Link button colors
  --clr-btn-link-color: var(--clr-color-action-500);
  --clr-btn-link-hover-color: var(--clr-color-action-600);
  --clr-btn-link-checked-color: var(--clr-color-action-700);

  // Navigation items
  --clr-sidenav-link-hover-color: var(--color-primary-100);
  --clr-sidenav-link-active-bg-color: var(--color-primary-100);
  --clr-responsive-nav-hover-bg: var(--color-primary-100);

  // Checkbox
  --clr-forms-checkbox-background-color: var(--color-primary-500);
  --clr-forms-checkbox-checked-shadow: var(--color-primary-500);
}
