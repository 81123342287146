:root {
  --gray-50: rgb(247 250 252);
  --gray-100: rgb(237 242 247);
  --gray-200: rgb(226 232 240);
  --gray-300: rgb(203 213 224);
  --gray-400: rgb(160 174 192);
  --gray-500: rgb(113 128 150);
  --gray-600: rgb(74 85 104);
  --gray-700: rgb(45 55 72);
  --gray-800: rgb(26 32 44);
  --gray-900: rgb(23 25 35);
}

// Overrides for dark theme from Vendure
// ~@vendure/admin-ui/static/styles/theme/dark
[data-theme='dark'] {
  --color-grey-100: var(--gray-100);
  --color-grey-200: var(--gray-200);
  --color-grey-300: var(--gray-300);
  --color-grey-400: var(--gray-400);
  --color-grey-500: var(--gray-500);
  --color-grey-600: var(--gray-600);
  --color-grey-700: var(--gray-700);
  --color-grey-800: var(--gray-800);
  --color-grey-900: var(--gray-900);

  // Background colors
  --color-component-bg-100: var(--gray-900);
  --color-component-bg-200: var(--gray-800);
  --color-component-bg-300: var(--gray-700);

  // Border colors
  --color-component-border-100: var(--color-grey-700);
  --color-component-border-200: var(--color-grey-600);
  --color-component-border-300: var(--color-grey-500);

  // Font colors
  --color-text-100: hsl(210deg 16% 93%);
  --color-text-200: hsl(203deg 16% 72%);
  --color-text-300: var(--color-grey-300);

  // Global colors
  --clr-global-app-background: var(--gray-900);
  --clr-global-selection-color: var(--gray-700);
  --clr-global-hover-bg-color: var(--gray-600);

  // Link colors
  --clr-link-active-color: var(--color-primary-500);
  --clr-link-color: var(--color-primary-500);
  --clr-link-hover-color: var(--color-primary-500);
  --clr-link-visited-color: var(--color-primary-700);

  /*****************
   * Buttons
   */
  // Default button colors
  --clr-btn-default-color: var(--color-grey-50);
  --clr-btn-default-border-color: var(--clr-btn-default-color);
  --clr-btn-default-hover-color: var(--color-grey-50);

  // Default outline button
  --clr-btn-default-outline-hover-color: var(--clr-color-action-800);

  // Primary button colors
  --clr-btn-primary-color: var(--clr-color-neutral-0);
  --clr-btn-primary-bg-color: var(--clr-color-action-500);
  --clr-btn-primary-border-color: var(--clr-color-action-500);
  --clr-btn-primary-hover-bg-color: var(--clr-color-action-600);
  --clr-btn-primary-hover-color: var(--clr-color-neutral-0);
  --clr-btn-primary-box-shadow-color: var(--clr-color-action-500);
  --clr-btn-primary-checked-bg-color: var(--clr-color-action-500);
  --clr-btn-primary-checked-color: var(--clr-color-neutral-0);

  // Link button colors
  --clr-btn-link-color: var(--clr-color-action-500);
  --clr-btn-link-hover-color: var(--clr-color-action-600);
  --clr-btn-link-checked-color: var(--clr-color-action-700);

  // Checkbox
  --clr-forms-checkbox-background-color: var(--color-primary-500);
  --clr-forms-checkbox-checked-shadow: var(--color-primary-500);

  /**********
   * Header
   */
  --clr-header-bg-color: var(--gray-900);

  // END Header overrides

  /***************
   * Nav
   */
  --clr-nav-background-color: var(--color-component-bg-200);
  --clr-subnav-bg-color: var(--color-component-bg-200);
  --clr-sidenav-link-hover-color: var(--color-component-bg-200);
  --clr-sidenav-link-active-bg-color: var(--color-component-bg-300);
  --clr-responsive-nav-hover-bg: var(--clr-global-selection-color);

  // END Nav

  /*********
   * Spinner
   */
  --clr-spinner-color: var(--clr-color-action-500);
  --clr-spinner-bg-color: var(--clr-color-action-800);

  // END Spinners

  /**********
   * Table
   */
  --clr-thead-bgcolor: var(--gray-800);
  --clr-table-bgcolor: var(--gray-800);

  // END: Table

  /**********
   * Tabs
   */
  --clr-nav-active-box-shadow-color: var(--clr-color-action-500);

  // END: Tabs

  /**********
   * Card
   */
  --clr-card-bg-color: var(--gray-800);

  // END: Card
}
