:root {
  // Colors
  --color-primary-100: #c2d7c1;
  --color-primary-200: #a8c5a6;
  --color-primary-300: #8db38a;
  --color-primary-400: #72a16e;
  --color-primary-500: #578f53;
  --color-primary-600: #4e814e;
  --color-primary-700: #3f683f;
  --color-primary-800: #305030;
  --color-primary-900: #223722;
  --color-secondary-100: #f8d1c4;
  --color-secondary-200: #f5bcaa;
  --color-secondary-300: #f2a790;
  --color-secondary-400: #ef9376;
  --color-secondary-500: #ec7e5c;
  --color-secondary-600: #c66a4d;
  --color-secondary-700: #a1563f;
  --color-secondary-800: #7b4230;
  --color-secondary-900: #552d21;
  --color-white: #fff;
  --color-grey-50: #f7fafc;
  --color-grey-100: #edf2f7;
  --color-grey-200: #e2e8f0;
  --color-grey-300: #a6afc1; // Deviating from the color in the design (#cbd5e0) to achieve a better contrast in the admin frontend
  --color-grey-400: #a0aec0;
  --color-grey-500: #718096;
  --color-grey-600: #4a5568;
  --color-grey-700: #2d3748;
  --color-grey-800: #1a202c;
  --color-grey-900: #171923;
  --color-black: #000;

  // Action colors
  --clr-color-action-50: var(--color-primary-100);
  --clr-color-action-100: var(--color-primary-100);
  --clr-color-action-200: var(--color-primary-200);
  --clr-color-action-300: var(--color-primary-300);
  --clr-color-action-400: var(--color-primary-400);
  --clr-color-action-500: var(--color-primary-500);
  --clr-color-action-600: var(--color-primary-600);
  --clr-color-action-700: var(--color-primary-700);
  --clr-color-action-800: var(--color-primary-800);
  --clr-color-action-900: var(--color-primary-900);
  --clr-color-action-1000: var(--color-primary-900);

  // Universal semantic colors
  --color-component-bg-100: var(--color-white);
  --color-component-bg-200: var(--color-grey-100);
  --color-component-bg-300: var(--color-grey-200);
  --color-component-border-100: var(--color-grey-200);
  --color-component-border-200: var(--color-grey-300);
  --color-component-border-300: var(--color-grey-400);
  --color-text-100: var(--clr-global-font-color);
  --color-text-200: var(--clr-global-font-color-secondary);
  --color-text-300: var(--color-grey-400);

  // Background color
  --clr-global-app-background: var(--color-component-bg-0);

  // Header colors
  --color-header-gradient-from: var(--gray-900);
  --color-header-gradient-to: var(--gray-900);
  --clr-header-bg-color: var(--gray-900);
  --clr-nav-background-color: var(--gray-50);

  // Placeholder color
  --clr-forms-placeholder-color: var(--color-text-300);

  // Link colors
  --clr-link-visited-color: var(--clr-global-font-color);
}
